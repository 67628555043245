<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card v-bind:title="''">
          <template v-slot:toolbar> </template>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-between my-3">
              <div class="col-md-6">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>

              <div class="col-md-3 col-12">
                <b-form-select
                  v-model="filter.is_satusehat_upload"
                  class="mb-3"
                  @input="filterByStatus"
                >
                  <b-form-select-option value=""
                    >Filter Status</b-form-select-option
                  >
                  <b-form-select-option value="1"
                    >Diposting</b-form-select-option
                  >
                  <b-form-select-option value="0"
                    >Proses Posting</b-form-select-option
                  >
                </b-form-select>
              </div>
              <div class="col-md-3 col-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pasien"
                    @keyup="patientOnKeyUp"
                  ></b-form-input>
                </b-input-group>
              </div>
              <!-- <div class="col-md-3 col-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <treeselect
                    v-model="filter.diagnose_id"
                    :multiple="true"
                    placeholder="Cari Diagnosa"
                    :options="diagnoseItems"
                    @select="diagnoseOnSelect"
                    @deselect="diagnoseOnDeselect"
                  />
                  <b-form-input
                    type="text"
                    v-model="filter.diagnose"
                    placeholder="Cari Diagnosa"
                    @keyup="patientOnKeyUp"
                  ></b-form-input>
                </b-input-group>
              </div> -->
            </div>

            <!-- timeseries filter -->
            <div class="row">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="pagination" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="pagination"
                            variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span
                  style="width: 150px; color: #a12260 !important"
                  class="font-weight-bold"
                  >{{ recordData }} Baris Data</span
                >
              </div>
            </div>

            <!-- table -->
            <div v-if="dataLoaded == true">
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                responsive="sm"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{
                      width: field.key === 'actions' ? '5%' : '',
                    }"
                  />
                </template>

                <template #cell(title)="data">
                  <span v-html="data.item.list_title"></span>
                </template>

                <template #cell(display_ss_posting_date)="data">
                  {{
                    new Date(data.item.ss_posting_date).toLocaleString(
                      "id-ID",
                      {
                        weekday: "long",
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )
                  }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                    v-if="
                      data.item.is_bridge_upload == 1 &&
                      data.item.is_satusehat_upload == 1
                    "
                    style="background-color: #28a745; border-color: #28a745; color: white"
                    >Diposting</b-badge
                  >
                  <b-badge
                    v-if="
                      data.item.is_bridge_upload == 1 &&
                      data.item.is_satusehat_upload == 0
                    "
                    style="background-color: #ffc107; border-color: #ffc107; color: white"
                    >Proses Posting</b-badge
                  >
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    @click="
                      $router.push(
                        `/satu-sehat/patients/detail/${data.item.id}/0`
                      )
                    "
                    v-b-tooltip.hover
                    title="Detail"
                    placement="bottom"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </b-button>
                </template>
              </b-table>
              <!-- pagination -->
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import Card from "@/view/content/Card.vue";
import { getUser } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { debounce } from "@/core/modules/Helper.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  components: {
    Card,
  },
  data() {
    return {
      // data
      items: null,
      recordData: 0,
      formData: new FormData(),
      page: "add",
      // patientData: [],
      attachment: "",
      displayImage: [],
      displayAttachment: [],
      display: {
        patient_name: this.$route.query.patient_name ?? "",
      },
      doctors: [],

      // date
      date: "",
      time: "",

      // collapse
      addCollapse: false,
      editCollapse: false,

      // diagnose
      diagnoseItems: [],

      // other
      dataLoaded: false,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_ss_posting_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name_relation",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "patient_address",
          label: "Alamat",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],

      edit: [],
      detail: [],
      // data
      show: true,

      // draw
      options: {
        penColor: "#000000",
      },
      // access management
      manipulateBtn: false,
      // filter
      filter: {
        name: "",
        is_satusehat_upload: "",
        diagnose_id: [],
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // patient info data
      patient: {
        allergic: this.$route.query.allergy,
        notes: this.$route.query.notes,
      },
      // other
      doctorDisabled: true,
      currentUser: getUser(),
      formCheckAll: 0,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let route;
      if (this.currentUser.user_type_id == 1) {
        route = `medical-records/get-by-doctor-in-charge/${this.currentUser.user_id}`;
      } else {
        route = "medical-records";
      }

      let filterParams = `&is_satusehat_upload=${this.filter.is_satusehat_upload}&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&purpose=satusehat`;
      filterParams += "&is_bridge_upload=1";
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;
      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
      this.dataLoaded = true;
    },
    filterByStatus(evt) {
      console.log("evt", evt);
      this.pagination();
    },
    resetFilter() {
      this.filter = validation.clearForm(this.filter);
      this.filter.diagnose_id = [];
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },
    patientOnKeyUp() {
      debounce(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (this.filter.name == "") {
          this.btnActiveOnClick(this.lastPeriodType);
        }
        this.pagination();
        // this.$root.$emit("setFilterDataOnClick", this.filter);
      }, 500);
    },
    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }

      this.$root.$emit("setFilterDataOnClick", this.filter);
    },
  },
  mounted() {
    this.pagination();
  },
};
</script>

<style>
</style>